<template>
  <div class="about">
    <h1>This is an about page</h1>
    <h2>hello world</h2>
  </div>
</template>

<style lang="scss">
.about{
  h1{
    color:red;
  }
}
</style>
